<template>
<div class="mainform">
  <div class="mainHeader">
      {{formData.itemName}}
      <span @click="back" class="back">返回></span>
    </div>
  <div class="form">
    <div class="header">招标信息
      <span class="showOff">
        <el-button @click="open" size="small" type="text">{{isclose?"展开":"收起"}}
          <i :class="[{'el-icon-arrow-down':isclose},{'el-icon-arrow-up':!isclose}]"></i>
        </el-button>
      </span>
      </div>
    <bid-all-info v-if="!isclose" :formData="formData"></bid-all-info>
    <div class="header">投标单位报价表</div>
    <quotation-sheet-bidder class="quota" :formData="formData"></quotation-sheet-bidder>
    <div class="header">专家评分</div>
    <expert-rating ref="expertRating" :formData="formData"></expert-rating>
    <div class="action" v-if="status==0">
      <el-button @click="submit" class="submit" type="primary" size="medium">提交评分</el-button>
    </div>
  </div>
</div>
</template>

<script>
import { dataFormat } from './js/bidConfig'
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  components: Component.components,
  name: 'evaluationBidForm',
  data () {
    var id = this.$route.query.id
    var formdata = {}
    FlowCtlData.docid = ''
    FlowCtlData.flowid = 'bid'
    var status = this.$route.query.status
    if (id) {
      request('/api/bid/getAllSupplierByBidId/' + id, 'get', {}).then((response) => {
        if (response.code === '200') {
          formdata = response.data
          this.formData = response.data
        }
      })
      FlowCtlData.docid = id
      FlowCtlData.initialize()
    }
    return {
      formData: formdata,
      dataFormat: dataFormat,
      isclose: true,
      status: status
    }
  },
  watch: {
    $route: {
      handler () {
        var id = this.$route.query.id
        if (id) {
          var status = this.$route.query.status
          this.status = status
          request('/api/bid/getAllSupplierByBidId/' + id, 'get', {}).then((response) => {
            if (response.code === '200') {
              this.formData = response.data
            }
          })
          FlowCtlData.docid = id
          FlowCtlData.initialize()
        }
      }
    }
  },
  created: function () {

  },
  methods: {
    open () {
      this.isclose = !this.isclose
    },
    submit: function () {
      var ratingData = []
      for (var s of this.$refs.expertRating.tableData[0].list) {
        for (var d of this.$refs.expertRating.tableData) {
          var tempBidData = {
            bidId: '',
            expertMessageId: '',
            score: '',
            scoringId: '',
            supplierId: ''
          }
          tempBidData.score = d[s.supplierId]
          tempBidData.bidId = this.formData.id
          tempBidData.scoringId = d.scoringId
          tempBidData.supplierId = s.supplierId
          tempBidData.expertMessageId = this.$route.query.eid
          ratingData.push(tempBidData)
        }
      }
      let pass = true
      for (const item of ratingData) {
        if (item.score === '') {
          pass = false
          break
        }
      }
      if (!pass) {
        this.$message({
          type: 'error',
          message: '请将评分填写完整!'
        })
        return false
      }
      this.$confirm('是否提交评标结果？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request('/api/bid/saveScore', 'post', ratingData).then((response) => {
          if (response.code === '200') {
            this.$message({
              type: 'success',
              message: '评分成功!'
            })
            window.history.back()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消提交'
        })
      })
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
 @import '@/assets/css/form.scss';
 .quota{
   margin-top: 20px;
 }
 .showOff{
   float: right;
 }
 .action{
   margin-top: 40px;
 }
</style>
